<template>
  <div class="mobileBrandIncubation-cantainer" ref="homeRef">
    <!-- 抬头 -->
    <MobileHeaderWrap />

    <div class="intro-wrap">
      <div class="intro-title">品牌孵化</div>
      <div class="intro-text">
        餐饮招商加盟、品牌孵化、数字营销及综合供应链服务
      </div>
      <div class="intro-text">
        专注于提供全方位的品牌解决方案，助力商业梦想照进现实
      </div>
      <div class="intro-button" @click="openMobileDe">专属顾问</div>
    </div>

    <div class="trim" ref="trimRef">
      <div class="trim-wrap">
        <div class="trim-title">
          {{ brandsNum }}<span class="unit">个</span>
        </div>
        <div class="trim-dec">孵化品牌数</div>
      </div>

      <div class="trim-wrap">
        <div class="trim-title">{{ gmvNum }}<span class="unit">亿</span></div>
        <div class="trim-dec">成交GMV</div>
      </div>

      <div class="trim-wrap">
        <div class="trim-title">
          {{ checkOutNum }}<span class="unit">家</span>
        </div>
        <div class="trim-dec">落店数</div>
      </div>
    </div>

    <div class="problem">
      <div class="problem-title">你是否面临这些问题</div>
      <div class="problem-wrap">
        <div class="problem-item">品牌扩张速度慢？</div>
        <div class="problem-item">投流效果差？</div>
        <div class="problem-item">产出比低？</div>
      </div>
    </div>

    <div class="case-list-wrap">
      <div class="title">一站式品牌增长</div>
      <div class="dec-wrap">
        <img src="../../assets/image/mobileBrandIncubation/decOne.png" alt="" />
        <img src="../../assets/image/mobileBrandIncubation/decTwo.png" alt="" />
        <img
          src="../../assets/image/mobileBrandIncubation/decThree.png"
          alt=""
        />
        <img
          src="../../assets/image/mobileBrandIncubation/decFour.png"
          alt=""
        />
      </div>

      <div class="all-btn-wrap">
        <div class="all-case-button" @click="handleBaseInfo">获取全套方案</div>
      </div>

      <div class="title">这些客户都在增长</div>
      <div class="case-wrap">
        <img
          src="../../assets/image/mobileBrandIncubation/caseOne.png"
          alt=""
          @click="handleCase"
        />

        <!-- <div class="case-button">了解更多</div> -->
      </div>
    </div>

    <MobileDeConsultant ref="mobileDeRef" />

    <BaseInforDrawer ref="baseInforDrawerRef" />

    <!-- 案例详情 -->
    <CaseDialog ref="caseDialogRef" />

    <!-- 底部 -->
    <MobileFooter ref="footerWrapRef" />

    <!-- 业务咨询footer -->
    <BusinessConsult :classNameShow="consultShow" />
  </div>
</template>

<script>
import { onMounted, ref, onUnmounted, nextTick } from 'vue'
import MobileFooter from '/@/components/mobileFooter/index.vue'
import MobileHeaderWrap from '/@/components/mobileHeaderWrap/index.vue'
import BusinessConsult from '/@/components/businessConsult/index.vue'
import MobileDeConsultant from '/@/components/mobileDeConsultant/index.vue'
import BaseInforDrawer from '/@/components/baseInforDrawer/index.vue'
import CaseDialog from '/@/components/caseDialog/index.vue'

export default {
  name: 'MobileBrandIncubation',
  components: {
    MobileFooter,
    MobileHeaderWrap,
    BusinessConsult,
    MobileDeConsultant,
    BaseInforDrawer,
    CaseDialog
  },
  setup() {
    const footerWrapRef = ref(null)
    const homeRef = ref(null)
    const consultShow = ref(true)
    const brandsNum = ref(0)
    const gmvNum = ref(0)
    const checkOutNum = ref(0)
    const trimRef = ref(null)
    const mobileDeRef = ref(null)
    const baseInforDrawerRef = ref(null)
    const caseDialogRef = ref(null)

    let consultTimer = null
    let animationFrameId

    // 案例详情
    const handleCase = () => {
      const caseInfoImg = new URL(
        `@/assets/image/caseDialog/fss.png`,
        import.meta.url
      ).href
      caseDialogRef.value.open(caseInfoImg)
    }

    const handleBaseInfo = () => {
      baseInforDrawerRef.value.open()
    }

    const openMobileDe = () => {
      mobileDeRef.value.open()
    }

    // 数字自增
    const animateNumbers = () => {
      const targetBrandsNum = 98
      const targetGmvNum = 135
      const targetCheckOutNum = 3550

      let startBrandsNum = brandsNum.value
      let startGmvNum = gmvNum.value
      let startCheckOutNum = checkOutNum.value

      const duration = 2000 // 设置动画持续时间，单位为毫秒
      const startTime = performance.now()

      const step = (timestamp) => {
        const elapsedTime = timestamp - startTime
        if (elapsedTime < duration) {
          const progress = elapsedTime / duration
          brandsNum.value = Math.abs(
            Math.floor(
              startBrandsNum + progress * (targetBrandsNum - startBrandsNum)
            )
          )
          gmvNum.value = Math.abs(
            Math.floor(startGmvNum + progress * (targetGmvNum - startGmvNum))
          )
          checkOutNum.value = Math.abs(
            Math.floor(
              startCheckOutNum +
                progress * (targetCheckOutNum - startCheckOutNum)
            )
          )
          requestAnimationFrame(step)
        } else {
          brandsNum.value = targetBrandsNum
          gmvNum.value = targetGmvNum
          checkOutNum.value = targetCheckOutNum
        }
      }

      requestAnimationFrame(step)
    }

    const handleScroll = () => {
      clearTimeout(consultTimer)
      consultShow.value = false
      consultTimer = setTimeout(() => {
        consultShow.value = true
      }, 500) // 这里设置1000毫秒（1秒），表示停止滚动1秒后恢复显示，可根据实际需求调整时间

      // 触发自增动画
      const rect = trimRef.value.getBoundingClientRect()
      const windowHeight = window.innerHeight

      if (rect.top < 0 || rect.top > windowHeight / 2) {
        if (brandsNum.value !== 0) {
          brandsNum.value = 0
        }
        if (gmvNum.value !== 0) {
          gmvNum.value = 0
        }
        if (checkOutNum.value !== 0) {
          checkOutNum.value = 0
        }
        if (animationFrameId) {
          cancelAnimationFrame(animationFrameId)
          animationFrameId = null
        }
      } else if (rect.top <= windowHeight - 50 && rect.top >= 0) {
        if (!animationFrameId) {
          animateNumbers()
        }
      }

      // 底部滚动到底部触发
      if (footerWrapRef.value) {
        const footerRect = footerWrapRef.value.$el
          .querySelector('.footer-wrap')
          .getBoundingClientRect()
        const windowHeight = window.innerHeight
        if (Math.floor(footerRect.bottom) <= windowHeight) {
          // 到达底部
          footerWrapRef.value.startAnimation()
        } else {
          // 离开底部
          footerWrapRef.value.getStart()
        }
      }
    }

    onMounted(() => {
      if (homeRef.value) {
        homeRef.value.addEventListener('scroll', handleScroll)
      }

      // 确保在挂载后获取正确的元素尺寸信息，因为可能在挂载时元素还未完全渲染好
      nextTick(() => {
        handleScroll()
      })
    })

    onUnmounted(() => {
      if (homeRef.value) {
        window.removeEventListener('scroll', handleScroll)
      }
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId)
      }
    })

    return {
      homeRef,
      footerWrapRef,
      consultShow,
      brandsNum,
      gmvNum,
      checkOutNum,
      trimRef,
      mobileDeRef,
      openMobileDe,
      baseInforDrawerRef,
      handleBaseInfo,
      caseDialogRef,
      handleCase
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>