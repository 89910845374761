<template>
  <div>
    <el-dialog
      v-model="visible"
      width="95%"
      append-to-body
      align-center
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      destroy-on-close
      style="border-radius: 0.32rem; padding: 0; margin-top: 0.4rem"
      :lock-scroll="true"
      class="caseDetailDialog"
    >
      <div class="close-icon">
        <el-icon @click="closeDialog">
          <close-bold />
        </el-icon>
      </div>
      <div class="case-dialog-wrap">
        <img :src="imgUrl" alt="" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import { CloseBold } from '@element-plus/icons'

export default {
  name: 'CaseDialog',
  components: {
    CloseBold
  },
  setup() {
    const visible = ref(false)
    const imgUrl = ref('')

    const open = (url) => {
      imgUrl.value = url
      visible.value = true
    }

    const closeDialog = () => {
      visible.value = false
    }

    return {
      visible,
      open,
      closeDialog,
      imgUrl
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>

<style>
.caseDetailDialog.el-dialog .el-dialog__header {
  display: none;
}
</style>
