<template>
  <div class="home-cantainer" ref="homeRef">
    <!-- 整合营销专家 -->
    <div class="header-one-wrap">
      <video class="video-background" autoplay loop muted preload="auto">
        <source
          src="https://zhongzhi-2023-1320182966.cos.ap-beijing.myqcloud.com/%E8%9E%8D%E6%99%BA%E4%BA%92%E5%8A%A8%E5%AE%98%E7%BD%91%E8%A7%86%E9%A2%91.mp4"
          type="video/mp4"
        />
      </video>

      <HeaderWarp
        navActive="首页"
        :pullDownStyle="`box-shadow: 0px 4px 29px 0px #C0CAF8;`"
      />

      <div class="inte-market-ape">整合营销专家</div>

      <div class="inte-market-dec one-line">
        精准策略，全面整合，让品牌影响力无处不在
      </div>
      <div class="inte-market-dec two-line">选择我们，开启您的营销新篇章！</div>
      <div class="btn-wrap" @click="clickInfoDia">
        <img
          class="arrow-img arrow-right"
          src="../../assets/image/home/red-right-arrow.png"
          alt=""
        />
        <div class="btn">业务咨询</div>
        <img
          class="arrow-img arrow-left"
          src="../../assets/image/home/red-left-arrow.png"
          alt=""
        />
      </div>
    </div>

    <!-- 智能营销工具 -->
    <div class="home-two-wrap">
      <div class="market-wrap">
        <div class="market-text">
          <div class="text en">SMART MARKETING TOOLS</div>
          <div class="text ch">智能营销工具</div>
        </div>
        <div class="market-dotte"></div>
      </div>

      <div class="chat-wrap" ref="chatRef">
        <div class="chat-box">
          <div class="chart-bg">
            <div
              class="chart-right-img right"
              style="height: 87px; width: 100%"
              :class="showIndex.includes(0) ? 'chart-img-right-block' : ''"
            >
              <img src="../../assets/image/home/customer1.png" alt="" />
            </div>
            <div
              style="height: 47px; width: 100%"
              class="chart-img left"
              :class="showIndex.includes(1) ? 'chart-img-left-block' : ''"
            >
              <img src="../../assets/image/home/client1.png" alt="" />
            </div>
            <div
              style="height: 87px; width: 100%; margin-top: 20px"
              class="chart-right-img right"
              :class="showIndex.includes(2) ? 'chart-img-right-block' : ''"
            >
              <img src="../../assets/image/home/customer2.png" alt="" />
            </div>
            <div
              style="height: 47px; width: 100%"
              class="chart-img left"
              :class="showIndex.includes(3) ? 'chart-img-left-block' : ''"
            >
              <img src="../../assets/image/home/client2.png" alt="" />
            </div>
          </div>

          <div class="chart-rigth">
            <div class="chart-rigth-item">
              <div class="title">大模型</div>
              <div class="dec">沟通更准确、更友好、更全面</div>
            </div>

            <div class="chart-rigth-item">
              <div class="title">快速集成</div>
              <div class="dec">秒级理解原始文档，智能客服，一键上岗</div>
            </div>

            <div class="chart-rigth-item">
              <div class="title">高效运营</div>
              <div class="dec">大模型协助机器人运营优化，省心省力</div>
            </div>
          </div>
        </div>

        <div class="chart-btn-box" @click="clickInfoDia">
          <div class="chart-btn-wrap">
            <img
              class="chart-arrow-img chart-arrow-right"
              src="../../assets/image/home/black-right-arrow.png"
              alt=""
            />
            <div class="chart-btn">申请试用</div>
            <img
              class="chart-arrow-img chart-arrow-left"
              src="../../assets/image/home/black-left-arrow.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 服务内容 -->
    <div class="home-three-wrap" ref="homeThreeWrapRef">
      <div class="service-wrap">
        <div class="service-text">
          <div class="text en">SERVICE CONTENT</div>
          <div class="text ch">服务内容</div>
        </div>
        <div class="service-dotte"></div>
      </div>

      <!-- 折叠区域 -->
      <div
        class="card local-life-wrap"
        ref="localLifeRef"
        @click="handleDetail('localLife')"
      >
        <div>
          <div class="card-title">本地生活</div>
          <div class="card-dec-wrap">
            <div class="card-dec">
              <div class="dec-text">项目陪跑</div>
              <div class="dec-text">直播代播</div>
              <div class="dec-text">技能培训</div>
              <div class="dec-text">线下探店</div>
              <div class="dec-text">行业报白</div>
            </div>
          </div>
        </div>
        <div class="img-div" ref="localLifeImgRef">
          <div class="show-more-div">
            <img
              class="img"
              src="../../assets/image/home/home-local-life.png"
              alt=""
            />
            <img
              class="show-more"
              src="../../assets/image/home/showMore.png"
              alt=""
            />
          </div>
        </div>
        <img
          class="icon"
          src="../../assets/image/home/home-local-life-icon.png"
          alt=""
        />
      </div>
      <!-- <div
        class="card e-commerce-live-wrap"
        ref="eCommerceLiveRef"
        @click="handleDetail('eCommerceLive')"
      >
        <div>
          <div class="card-title">电商直播</div>
          <div class="card-dec-wrap">
            <div class="card-dec">
              <div class="e-dec-text" style="z-index: 1">店铺代运营</div>
              <div class="e-dec-text" style="z-index: 1">账号代运营</div>
              <div class="e-dec-text" style="z-index: 1">直播代运营</div>
              <div class="e-dec-text" style="z-index: 1">短视频带货</div>
              <div class="e-dec-text" style="z-index: 1">客服电销服务</div>
            </div>
          </div>
        </div>
        <div class="img-div">
          <div class="show-more-div">
            <img
              class="img"
              src="../../assets/image/home/home-commerve-live.png"
              alt=""
            />
            <img
              class="show-more"
              src="../../assets/image/home/showMore.png"
              alt=""
            />
          </div>
        </div>
        <img
          class="icon"
          src="../../assets/image/home/home-commerve-live-icon.png"
          alt=""
        />
      </div> -->
      <div
        class="card performance-advertising-wrap"
        ref="performanceAdvertisingRef"
        @click="handleDetail('performanceAd')"
      >
        <div>
          <div class="card-title">效果广告</div>
          <div class="card-dec-wrap">
            <div class="card-dec">
              <div class="e-dec-text" style="width: 176px; z-index: 1">
                巨量千川代运营
              </div>
              <div class="e-dec-text" style="width: 176px; z-index: 1">
                巨量信息流获客
              </div>
              <div class="e-dec-text" style="width: 150px; z-index: 1">
                广告素材制作
              </div>
              <div class="e-dec-text" style="width: 176px; z-index: 1">
                付费流量投放
              </div>
            </div>
            <div class="card-dec">
              <div class="e-dec-text" style="width: 176px; z-index: 1">
                线上精准获客
              </div>
              <div class="e-dec-text" style="width: 176px; z-index: 1">
                直播/账号涨粉
              </div>
              <div class="e-dec-text" style="width: 176px; z-index: 1">
                电商产品成交
              </div>
            </div>
          </div>
        </div>
        <div class="img-div">
          <div class="show-more-div">
            <img
              class="img"
              src="../../assets/image/home/home-performance-ad.png"
              alt=""
            />
            <img
              class="show-more"
              src="../../assets/image/home/showMore.png"
              alt=""
            />
          </div>
        </div>

        <img
          class="icon"
          src="../../assets/image/home/home-performance-ad-icon.png"
          alt=""
        />
      </div>
      <div
        class="card brand-communication-wrap"
        ref="brandCommunicationRef"
        @click="handleDetail('brandComm')"
      >
        <div>
          <div class="card-title">品牌传播</div>
          <div class="card-dec-wrap">
            <div class="card-dec">
              <div class="dec-text" style="z-index: 1">品牌整合营销</div>
              <div class="dec-text" style="z-index: 1">广告媒介投放</div>
              <div class="dec-text" style="z-index: 1">明星达人合作</div>
              <div class="dec-text" style="z-index: 1">热点话题炒作</div>
            </div>
            <div class="card-dec">
              <div class="dec-text" style="z-index: 1">线上精准获客</div>
            </div>
          </div>
        </div>
        <div class="img-div">
          <div class="show-more-div">
            <img
              class="img"
              src="../../assets/image/home/home-brand-com.png"
              alt=""
            />
            <img
              class="show-more"
              src="../../assets/image/home/showMore.png"
              alt=""
            />
          </div>
        </div>

        <img
          class="icon"
          src="../../assets/image/home/home-brand-com-icon.png"
          alt=""
        />
      </div>

      <div
        class="card content-operation-wrap"
        ref="contentOperationRef"
        @click="handleDetail('contentOp')"
      >
        <div>
          <div class="card-title">内容运营</div>
          <div class="card-dec-wrap">
            <div class="card-dec">
              <div class="dec-text" style="width: 175px; z-index: 1">
                自媒体账号运营
              </div>
              <div class="dec-text" style="width: 135px; z-index: 1">
                IP/矩阵打造
              </div>
              <div class="dec-text" style="width: 155px; z-index: 1">
                TVC/视频拍摄
              </div>
              <div class="dec-text" style="width: 175px; z-index: 1">
                品牌视觉设计
              </div>
            </div>
            <div class="card-dec">
              <div class="dec-text" style="width: 175px; z-index: 1">
                插画/海报设计
              </div>
              <div class="dec-text" style="width: 175px; z-index: 1">
                营销物料设计
              </div>
            </div>
          </div>
        </div>
        <div class="img-div">
          <div class="show-more-div">
            <img
              class="img"
              src="../../assets/image/home/home-content-operation.png"
              alt=""
            />
            <img
              class="show-more"
              src="../../assets/image/home/showMore.png"
              alt=""
            />
          </div>
        </div>

        <img
          class="icon"
          src="../../assets/image/home/home-content-operation-icon.png"
          alt=""
        />
      </div>

      <!-- 底部 -->
      <div class="sticky" ref="footerWrapRef">
        <div class="home-footer-wrap">
          <div class="home-footer-dec">
            <div class="home-logo-wrap">
              <img
                style="height: 56px"
                src="../../assets/image/home/footer-logo.png"
                alt=""
              />
            </div>
            <div class="home-contacts-wrap">
              <div class="home-contacts">Contacts</div>
              <!-- <div class="home-phone">189-8008-9917</div> -->
              <div class="home-phone" style="margin-bottom: 27px">
                185-8399-2018
              </div>
              <div class="home-poi">中国·成都·武侯区高新科技工业园F座1楼</div>
              <div class="home-poi">went@kingwisoft.com</div>
              <div class="home-poi">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  title="(新页面打开)"
                  href="https://beian.miit.gov.cn/"
                  style="color: #ffffff"
                >
                  蜀ICP备：2024100140号-1
                </a>
              </div>
            </div>
          </div>

          <div class="home-round-wrap" ref="roundRef">
            <img
              v-for="(imgItem, imgIndex) in footerImgs"
              :key="imgIndex"
              :src="imgItem.url"
              alt=""
              class="scroll-img"
              :style="imgIndex == 0 ? '' : 'margin-left: 100px;'"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 业务咨询弹窗 -->
    <InformationDialog ref="infoDiaRef" />
  </div>
</template>

<script>
import { onMounted, ref, onUnmounted } from 'vue'
import imgUrl from '/@/assets/image/home/home-go-round.png'
import HeaderWarp from '/@/components/headerWrap/index.vue'
import { useRouter } from 'vue-router'
import InformationDialog from '/@/components/informationDialog/index.vue'

export default {
  name: 'HomePage',
  components: {
    HeaderWarp,
    InformationDialog
  },
  setup() {
    const router = useRouter()

    // ref定义
    const homeRef = ref(null)
    const chatRef = ref(null)
    const showIndex = ref([])
    const localLifeRef = ref(null)
    const localLifeImgRef = ref(null)
    // const eCommerceLiveRef = ref(null)
    const performanceAdvertisingRef = ref(null)
    const brandCommunicationRef = ref(null)
    const contentOperationRef = ref(null)
    const footerWrapRef = ref(null)
    const homeThreeWrapRef = ref(null)
    const roundRef = ref(null)
    const infoDiaRef = ref(null)
    const footerImgs = ref([
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl },
      { url: imgUrl }
    ])
    // 当前处于移动index
    const currentIndex = ref(0)
    // 上次滚动高度 用来判断滚动方向
    const prevScrollY = ref(0)
    // 每张压住上一张最大距离
    const maxTrans = ref(0)
    // 图片高度
    const imgHei = ref(0)
    let timer = null
    let isAnimating = false
    // 满足条件只触发一次开关
    let hasTriggeredOnce = false
    // 到达触发位置鼠标滚动距离
    let prevTranslateY = 0

    // 每张压住上一张最大距离\图片高度方法
    const initMaxTrans = () => {
      if (localLifeRef.value) {
        maxTrans.value = localLifeRef.value.offsetHeight / 2
        imgHei.value = localLifeImgRef.value.offsetHeight
      }
    }

    const handleScroll = () => {
      // 聊天动效逻辑
      const top = chatRef.value.getBoundingClientRect().top
      if (top <= 200 && top >= -760 && !hasTriggeredOnce) {
        hasTriggeredOnce = true
        if (!isAnimating) {
          isAnimating = true
          timer = setInterval(() => {
            if (showIndex.value.length < 4) {
              showIndex.value.push(showIndex.value.length)
            } else {
              clearInterval(timer)
              timer = null
              isAnimating = false
            }
          }, 900)
        }
      } else if (hasTriggeredOnce && (top > 200 || top < -760)) {
        if (timer) {
          clearInterval(timer)
          timer = null
          isAnimating = false
        }
        showIndex.value = []
        hasTriggeredOnce = false
      }

      // 折叠卡片数组
      const cards = [
        localLifeRef.value,
        // eCommerceLiveRef.value,
        performanceAdvertisingRef.value,
        brandCommunicationRef.value,
        contentOperationRef.value,
        footerWrapRef.value
      ]

      // 判断是否向上滚动
      // isScrollingUp：fasle向下 true向上
      const currentScrollY = homeRef.value.scrollTop
      const isScrollingUp = currentScrollY < prevScrollY.value
      prevScrollY.value = currentScrollY

      // 折叠逻辑
      if (currentIndex.value < cards.length) {
        // 当前选中盒子距离标准线滚动距离
        const topB = cards[currentIndex.value].getBoundingClientRect().top
        // 距离上视口 1/3 的距离
        const topA = window.innerHeight * 0.3

        if (topB > 0) {
          prevTranslateY = topA - topB
        } else if (topB === 0) {
          prevTranslateY = topA
        } else if (topB < 0) {
          prevTranslateY = topA + topB
        }

        if (!isScrollingUp) {
          if (
            cards[currentIndex.value].getBoundingClientRect().top <= topA &&
            cards[currentIndex.value].getBoundingClientRect().top > 0
          ) {
            if (Math.abs(prevTranslateY) < maxTrans.value) {
              if (currentIndex.value < 3) {
                const imgElements =
                  cards[currentIndex.value].querySelectorAll('.img-div')
                imgElements.forEach((img) => {
                  img.style.height = `${
                    imgHei.value - Math.abs(prevTranslateY)
                  }px`
                })
              }

              if (currentIndex.value < 1 && cards[1]) {
                // 累计前面卡片的位移
                cards[1].style.transform = `translateY(-${
                  Math.abs(prevTranslateY) + maxTrans.value * currentIndex.value
                }px)`
              }
              if (currentIndex.value < 2 && cards[2]) {
                // 累计前面卡片的位移
                cards[2].style.transform = `translateY(-${
                  Math.abs(prevTranslateY) + maxTrans.value * currentIndex.value
                }px)`
              }
              if (currentIndex.value < 3 && cards[3]) {
                // 累计前面卡片的位移
                cards[3].style.transform = `translateY(-${
                  Math.abs(prevTranslateY) + maxTrans.value * currentIndex.value
                }px)`
              }
              if (currentIndex.value < 3 && cards[4]) {
                // 累计前面卡片的位移
                cards[4].style.transform = `translateY(-${
                  Math.abs(prevTranslateY) + maxTrans.value * currentIndex.value
                }px)`
              }
              // if (currentIndex.value < 4 && cards[5]) {
              //   // 累计前面卡片的位移
              //   cards[5].style.transform = `translateY(-${
              //     Math.abs(prevTranslateY) + maxTrans.value * currentIndex.value
              //   }px)`
              // }

              // 获取位移后溢出高度
              calculateDistance()
            } else {
              if (currentIndex.value < 5) {
                currentIndex.value++
              }
            }
          }
        } else {
          if (cards[currentIndex.value].getBoundingClientRect().top > topA) {
            if (Math.abs(prevTranslateY) < maxTrans.value) {
              if (currentIndex.value < 4) {
                const imgElements =
                  cards[currentIndex.value].querySelectorAll('.img-div')
                imgElements.forEach((img) => {
                  img.style.height = `${imgHei.value}px`
                })
              }

              if (currentIndex.value < 1 && cards[1]) {
                // 累计前面卡片的位移
                cards[1].style.transform = `translateY(-${
                  maxTrans.value * currentIndex.value
                }px)`

                cards[1].style.transition = 'all 0.1s linear'
              }
              if (currentIndex.value < 2 && cards[2]) {
                // 累计前面卡片的位移
                cards[2].style.transform = `translateY(-${
                  maxTrans.value * currentIndex.value
                }px)`

                cards[2].style.transition = 'all 0.1s linear'
              }
              if (currentIndex.value < 3 && cards[3]) {
                // 累计前面卡片的位移
                cards[3].style.transform = `translateY(-${
                  maxTrans.value * currentIndex.value
                }px)`

                cards[3].style.transition = 'all 0.1s linear'
              }
              if (currentIndex.value < 4 && cards[4]) {
                // 累计前面卡片的位移
                cards[4].style.transform = `translateY(-${
                  maxTrans.value * currentIndex.value
                }px)`

                cards[4].style.transition = 'all 0.1s linear'
              }
              if (currentIndex.value < 4 && cards[5]) {
                // 累计前面卡片的位移
                cards[5].style.transform = `translateY(-${
                  maxTrans.value * currentIndex.value
                }px)`

                cards[5].style.transition = 'all 0.1s linear'
              }
              // 获取位移后溢出高度
              calculateDistance()
            } else {
              if (currentIndex.value > 0) {
                currentIndex.value--
              }
            }
          }
        }
      }

      // 底部滚动到底部触发
      if (footerWrapRef.value) {
        const footerRect = roundRef.value.getBoundingClientRect()
        const windowHeight = window.innerHeight
        if (footerRect.bottom <= windowHeight) {
          // 到达底部
          startAnimation()
        } else {
          // 离开底部
          getStart()
        }
      }
    }

    // 获取位移后溢出高度
    const calculateDistance = () => {
      if (homeThreeWrapRef.value && footerWrapRef.value) {
        const homeThreeWrapRect = homeThreeWrapRef.value.getBoundingClientRect()
        const footerWrapRect = footerWrapRef.value.getBoundingClientRect()

        const homeThreeWrapBottom = homeThreeWrapRect.bottom
        const footerWrapBottom = footerWrapRect.bottom

        const distance = homeThreeWrapBottom - footerWrapBottom

        // 获取 homeThreeWrapRef 的高度并减去 distance
        const homeThreeWrapHeight = homeThreeWrapRef.value.clientHeight
        const newHeight = homeThreeWrapHeight - distance

        // 赋值给 homeThreeWrapRef 的高度
        homeThreeWrapRef.value.style.height = newHeight - 30 + 'px'
      }
    }

    // 开始滚动
    const startAnimation = () => {
      if (roundRef.value) {
        const containerWidth = roundRef.value.offsetWidth
        const imgWidth = document.querySelector('.scroll-img').offsetWidth
        const totalWidth = imgWidth * footerImgs.value.length
        const speed = totalWidth / 150

        roundRef.value.style.animation = `scrollLeft ${speed}s linear infinite`

        console.log(containerWidth, imgWidth, totalWidth, 'containerWidth')
      }
    }

    // 重置图片位置
    const getStart = () => {
      roundRef.value.style.animation = 'none'
    }

    // 跳转案例详情
    const handleDetail = (id) => {
      // /integrated/secondLevel/localLife
      // router.push({ name: 'CaseDetail', params: { id: id } })
      router.push({ name: 'IntegratedSecond', params: { id: id } })
    }

    // 打开业务咨询弹窗
    const clickInfoDia = () => {
      infoDiaRef.value.open()
    }

    onMounted(() => {
      initMaxTrans()
      homeRef.value.addEventListener('scroll', handleScroll)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll)
      if (timer) {
        clearInterval(timer)
      }
    })

    return {
      homeRef,
      chatRef,
      showIndex,
      localLifeRef,
      // eCommerceLiveRef,
      performanceAdvertisingRef,
      brandCommunicationRef,
      contentOperationRef,
      footerWrapRef,
      currentIndex,
      prevScrollY,
      localLifeImgRef,
      homeThreeWrapRef,
      roundRef,
      footerImgs,
      imgUrl,
      handleDetail,
      infoDiaRef,
      clickInfoDia
    }
  }
}
</script>
<style lang="css" scoped>
@import './index.css';
</style>