<template>
  <div id="app" :style="!isMobile() ? 'min-width: 1450px;' : ''">
    <!-- 开屏视频 -->
    <div v-if="!isMobile() && showSplashScreen" class="splash-screen">
      <video
        ref="videoRef"
        autoplay
        muted
        :loop="false"
        src="./assets/image/home/home-screen-video.mp4"
      ></video>
    </div>
    <div v-else>
      <router-view ref="routerView"></router-view>
    </div>

    <!-- 快速联系 -->
    <img
      v-if="!isMobile() && !showSplashScreen"
      class="quick-contact"
      src="./assets/image/home/quick-contact-icon.png"
      alt=""
      @click="clickQuickContact"
    />

    <!-- 快速联系弹窗 -->
    <QuickContact ref="quickContactRef" />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import QuickContact from '/@/components/quickContact/index.vue'
import { isMobile } from '@/utils/device.js' // 引入判断设备类型的函数

export default {
  name: 'App',
  components: {
    QuickContact
  },
  setup() {
    const videoRef = ref(null)
    const showSplashScreen = ref(true)
    const quickContactRef = ref(null)
    const routerView = ref(null)

    // 快速联系
    const clickQuickContact = () => {
      quickContactRef.value.open()
    }

    onMounted(() => {
      const video = videoRef.value
      if (video) {
        video.addEventListener('loadedmetadata', () => {
          video.addEventListener('ended', handleVideoEnd)
        })
      }
    })

    const handleVideoEnd = () => {
      showSplashScreen.value = false
    }

    return {
      videoRef,
      showSplashScreen,
      quickContactRef,
      clickQuickContact,
      routerView,
      isMobile
    }
  }
}
</script>

<style lang="scss" >
/* 全局样式 自定义样式 */
@import '@/assets/styles/index.scss';

@media (min-width: 1601px) {
  @import '@/assets/styles/largeIndex.scss';
}

@media (max-width: 1600px) {
  @import '@/assets/styles/middleIndex.scss';
}

.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.quick-contact {
  cursor: pointer;
  width: 80px;
  height: 80px;
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 1;
}
</style>