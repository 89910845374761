<template>
  <div class="brandIncubation-cantainer" ref="homeRef">
    <!-- 品牌孵化二级页 -->

    <div class="header-bg">
      <HeaderWarp navActive="品牌孵化" />

      <div class="header-title">
        <div class="title-box">
          <div class="inte-market-ape">品牌孵化</div>
          <div class="inte-market-dec one-line">
            餐饮招商加盟、品牌孵化、数字营销及综合供应链服务
          </div>
          <div class="inte-market-dec two-line">
            专注于提供全方位的品牌解决方案，助力商业梦想照进现实
          </div>
        </div>
      </div>

      <div class="btn-wrap" @click="clickDeConsultant">
        <img
          class="arrow-img arrow-right"
          src="../../assets/image/home/red-right-arrow.png"
          alt=""
        />
        <div class="btn">专属顾问</div>
        <img
          class="arrow-img arrow-left"
          src="../../assets/image/home/red-left-arrow.png"
          alt=""
        />
      </div>
    </div>

    <div class="trim flex-space" ref="trimRef">
      <div class="trim-wrap">
        <div class="trim-title">
          {{ brandsNum }}<span class="unit">个</span>
        </div>
        <div class="trim-dec">孵化品牌数</div>
      </div>

      <div class="trim-wrap">
        <div class="trim-title">{{ gmvNum }}<span class="unit">亿</span></div>
        <div class="trim-dec">成交GMV</div>
      </div>

      <div class="trim-wrap">
        <div class="trim-title">
          {{ checkOutNum }}<span class="unit">家</span>
        </div>
        <div class="trim-dec">落店数</div>
      </div>
    </div>

    <div class="problem">
      <div class="problem-title">你是否面临这些问题</div>
      <div class="flex-space">
        <div class="problem-item">品牌扩张速度慢？</div>
        <div class="problem-item">投流效果差？</div>
        <div class="problem-item">产出比低？</div>
      </div>
    </div>

    <div class="step">
      <div class="step-title">一站式品牌增长</div>
      <div class="step-body flex">
        <div
          class="step-item"
          :style="`top:${item.topSize * 60}px`"
          v-for="(item, index) in stepList"
          :key="index"
        >
          <div class="step-item-title">
            <img
              class="img"
              src="../../assets/image/brandIncubation/brandIncubation-qz.png"
              alt=""
            />
            <span> {{ item.title }}</span>
          </div>
          <div class="step-item-dec">
            <div
              class="text"
              v-for="(text, textIndex) in item.textList"
              :key="textIndex"
            >
              {{ text }}
            </div>
          </div>
        </div>
      </div>

      <img
        class="step-up"
        src="../../assets/image/brandIncubation/brandIncubation-up.png"
      />

      <div class="btn-wrap free-btn-wrap" @click="clickInfoDia">
        <img
          class="arrow-img arrow-right"
          src="../../assets/image/home/red-right-arrow.png"
          alt=""
        />
        <div class="btn">获取全套方案</div>
        <img
          class="arrow-img arrow-left"
          src="../../assets/image/home/red-left-arrow.png"
          alt=""
        />
      </div>
    </div>

    <div class="example">
      <div class="example-title">这些客户都在增长</div>
      <img
        class="example-case"
        src="../../assets/image/brandIncubation/brandIncubation-fss.png"
        alt=""
        @click="handleDetail('fss')"
      />
    </div>

    <!-- 底部 -->
    <Footer ref="footerWrapRef" />

    <!-- 业务咨询弹窗 -->
    <InformationDialog ref="infoDiaRef" />

    <!-- 专属顾问 -->
    <DeConsultant ref="deConsultantRef" />
  </div>
</template>

<script>
import { onMounted, ref, onUnmounted, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import HeaderWarp from '/@/components/headerWrap/index.vue'
import Footer from '/@/components/footer/index.vue'
import InformationDialog from '/@/components/informationDialog/index.vue'
import DeConsultant from '/@/components/deConsultant/index.vue'

export default {
  name: 'BrandIncubationPage',
  components: {
    HeaderWarp,
    Footer,
    InformationDialog,
    DeConsultant
  },
  setup() {
    const router = useRouter()
    const homeRef = ref(null)
    const footerWrapRef = ref(null)
    const trimRef = ref(null)
    const infoDiaRef = ref(null)
    const deConsultantRef = ref(null)
    let timer = null
    const stepList = ref([
      {
        title: '品牌孵化',
        topSize: 3,
        textList: [
          '0-1全流程服务，助力品牌门店运营、产品开发、视觉设计及优化。',
          '专业团队经验丰富，从创意到品牌实施，打造独特竞争力。',
          '精准市场调研，定位目标客群，策划品牌形象，制定营销策略，快速提升品牌影响力。'
        ]
      },
      {
        title: '品牌投资',
        topSize: 2,
        textList: [
          '发掘潜力品牌，投资并资源扶持，共促成长，实现双赢。',
          '专业的投资评估团队，筛选优质项目。',
          '提供资金支持，助力品牌拓展市场。',
          '共享行业资源，加速品牌发展进程。'
        ]
      },
      {
        title: '招商加盟服务',
        topSize: 1,
        textList: [
          '帮助品牌快速拓展市场，连接有创业梦想的您，共同开启成功之旅。',
          '提供详细的加盟政策和流程说明。协助加盟商进行店铺选址、装修和运营指导。',
          '持续的培训和支持，保障加盟商的顺利运营。'
        ]
      },
      {
        title: '供应链服务',
        topSize: 0,
        textList: [
          '高效、稳定的供应链体系，确保商品的及时供应和品质保障。',
          '与众多优质供应商建立长期合作关系。严格的质量检测，把控产品品质。',
          '优化物流配送，降低成本，提高效率。'
        ]
      }
    ])

    const brandsNum = ref(0)
    const gmvNum = ref(0)
    const checkOutNum = ref(0)

    let animationFrameId

    // 数字自增
    const animateNumbers = () => {
      const targetBrandsNum = 98
      const targetGmvNum = 135
      const targetCheckOutNum = 3550

      let startBrandsNum = brandsNum.value
      let startGmvNum = gmvNum.value
      let startCheckOutNum = checkOutNum.value

      const duration = 2000 // 设置动画持续时间，单位为毫秒
      const startTime = performance.now()

      const step = (timestamp) => {
        const elapsedTime = timestamp - startTime
        if (elapsedTime < duration) {
          const progress = elapsedTime / duration
          brandsNum.value = Math.abs(
            Math.floor(
              startBrandsNum + progress * (targetBrandsNum - startBrandsNum)
            )
          )
          gmvNum.value = Math.abs(
            Math.floor(startGmvNum + progress * (targetGmvNum - startGmvNum))
          )
          checkOutNum.value = Math.abs(
            Math.floor(
              startCheckOutNum +
                progress * (targetCheckOutNum - startCheckOutNum)
            )
          )
          requestAnimationFrame(step)
        } else {
          brandsNum.value = targetBrandsNum
          gmvNum.value = targetGmvNum
          checkOutNum.value = targetCheckOutNum
        }
      }

      requestAnimationFrame(step)
    }

    const handleScroll = () => {
      // 触发自增动画
      const rect = trimRef.value.getBoundingClientRect()
      const windowHeight = window.innerHeight

      if (rect.top < 0 || rect.top > windowHeight / 2) {
        if (brandsNum.value !== 0) {
          brandsNum.value = 0
        }
        if (gmvNum.value !== 0) {
          gmvNum.value = 0
        }
        if (checkOutNum.value !== 0) {
          checkOutNum.value = 0
        }
        if (animationFrameId) {
          cancelAnimationFrame(animationFrameId)
          animationFrameId = null
        }
      } else if (rect.top <= windowHeight - 50 && rect.top >= 0) {
        if (!animationFrameId) {
          animateNumbers()
        }
      }

      // 底部滚动到底部触发
      if (footerWrapRef.value) {
        const footerRect = footerWrapRef.value.$el
          .querySelector('.footer-wrap')
          .getBoundingClientRect()
        const windowHeight = window.innerHeight
        if (Math.floor(footerRect.bottom) <= windowHeight) {
          // 到达底部
          footerWrapRef.value.startAnimation()
        } else {
          // 离开底部
          footerWrapRef.value.getStart()
        }
      }
    }

    // 打开业务咨询弹窗
    const clickInfoDia = () => {
      infoDiaRef.value.open()
    }

    // 打开专属顾问
    const clickDeConsultant = () => {
      deConsultantRef.value.open()
    }

    const handleDetail = (id) => {
      if (id) {
        router.push({ name: 'CaseDetail', params: { id: id } })
      }
    }

    onMounted(() => {
      homeRef.value.addEventListener('scroll', handleScroll)

      // 确保在挂载后获取正确的元素尺寸信息，因为可能在挂载时元素还未完全渲染好
      nextTick(() => {
        handleScroll()
      })
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll)
      if (timer) {
        clearInterval(timer)
      }
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId)
      }
    })

    return {
      homeRef,
      footerWrapRef,
      stepList,
      brandsNum,
      gmvNum,
      checkOutNum,
      trimRef,
      infoDiaRef,
      clickInfoDia,
      clickDeConsultant,
      deConsultantRef,
      handleDetail
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>