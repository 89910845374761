<template>
  <div class="mobile-integrated-second-cantainer" ref="homeRef">
    <!-- 抬头 -->
    <MobileHeaderWrap @pullClick="pullClick" />

    <div
      class="integrated-wrap"
      :style="`background-image: url(${infoData.bgUrl});`"
    >
      <div class="integrated-title">{{ infoData?.title || '' }}</div>
      <div
        v-for="(decItem, decIndex) in infoData.headerObj?.textList"
        :key="decIndex"
        class="integrated-text"
      >
        {{ decItem.center }}
      </div>
      <div class="integrated-button" @click="openMobileDe">
        {{ infoData?.headerObj?.buttonText || '' }}
      </div>
    </div>

    <!-- 卡片列表部分 -->
    <div
      v-if="infoData.problemObj?.type == 'card'"
      class="integrated-card-wrap"
    >
      <div class="title">{{ infoData?.problemObj?.title || '' }}</div>
      <div class="integrated-card-list-wrap">
        <img
          v-for="(imgItem, imgIndex) in infoData?.problemObj?.list"
          :key="imgIndex"
          class="img"
          :src="imgItem.cardUrl"
          alt=""
        />
      </div>
    </div>

    <!-- 文字列表部分 -->
    <div
      v-if="infoData.problemObj?.type == 'list'"
      class="integrated-card-wrap"
    >
      <div class="title">{{ infoData?.problemObj?.title || '' }}</div>

      <div class="list-wrap">
        <div
          v-for="(listItem, listIndex) in infoData.problemObj?.list"
          :key="listIndex"
          class="list-item"
        >
          {{ listItem.title }}
        </div>
      </div>

      <div class="more-wrap">
        <div class="more-button" @click="handleBaseInfo">
          {{ infoData?.problemObj?.btnText || '' }}
        </div>
      </div>
    </div>

    <!-- 案例卡片部分 -->
    <div class="case-card-wrap">
      <div class="title">{{ infoData?.exampleObj?.title || '' }}</div>
      <div class="center">{{ infoData?.exampleObj?.center || '' }}</div>
      <div class="case-card-list-wrap">
        <div
          v-for="(imgItem, imgIndex) in infoData?.exampleObj?.cardList"
          :key="imgIndex"
          @click="handleCase(imgItem)"
        >
          <img class="img" :src="imgItem.img" alt="" />
          <div class="dec">{{ imgItem.center }}</div>
          <div class="tag">{{ imgItem.tag }}</div>
        </div>
      </div>
    </div>

    <MobileDeConsultant ref="mobileDeRef" />

    <BaseInforDrawer ref="baseInforDrawerRef" />

    <!-- 案例详情 -->
    <CaseDialog ref="caseDialogRef" />

    <!-- 底部 -->
    <MobileFooter ref="footerWrapRef" />

    <!-- 业务咨询footer -->
    <BusinessConsult :classNameShow="consultShow" />
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import MobileHeaderWrap from '/@/components/mobileHeaderWrap/index.vue'
import BusinessConsult from '/@/components/businessConsult/index.vue'
import MobileFooter from '/@/components/mobileFooter/index.vue'
import { dataList } from '/@/utils/mobileIntegratedIndex.js'
import MobileDeConsultant from '/@/components/mobileDeConsultant/index.vue'
import BaseInforDrawer from '/@/components/baseInforDrawer/index.vue'
import CaseDialog from '/@/components/caseDialog/index.vue'

export default {
  name: 'mobileIntegratedSecond',
  components: {
    MobileHeaderWrap,
    BusinessConsult,
    MobileFooter,
    MobileDeConsultant,
    BaseInforDrawer,
    CaseDialog
  },
  setup() {
    const route = useRoute()

    const footerWrapRef = ref(null)
    const infoData = ref({})
    const homeRef = ref(null)
    const consultShow = ref(true)
    const mobileDeRef = ref(null)
    const baseInforDrawerRef = ref(null)
    const caseDialogRef = ref(null)
    const caseImg = ref()

    let consultTimer = null

    // 案例详情
    const handleCase = (item) => {
      caseDialogRef.value.open(item.caseInfoImg)
    }

    const handleBaseInfo = () => {
      baseInforDrawerRef.value.open()
    }

    const openMobileDe = () => {
      mobileDeRef.value.open()
    }

    const getInfo = () => {
      // 回滚到顶部
      scrollToTop()

      const id = route.params.id
      dataList.forEach((item) => {
        if (item.id == id) {
          infoData.value = item
        }
      })
    }

    const pullClick = (pull) => {
      if (pull.id) {
        getInfo()
      }
    }

    const handleScroll = () => {
      clearTimeout(consultTimer)
      consultShow.value = false
      consultTimer = setTimeout(() => {
        consultShow.value = true
      }, 500) // 这里设置1000毫秒（1秒），表示停止滚动1秒后恢复显示，可根据实际需求调整时间

      // 底部滚动到底部触发
      if (footerWrapRef.value) {
        const footerRect = footerWrapRef.value.$el
          .querySelector('.footer-wrap')
          .getBoundingClientRect()
        const windowHeight = window.innerHeight
        if (Math.floor(footerRect.bottom) <= windowHeight) {
          // 到达底部
          footerWrapRef.value.startAnimation()
        } else {
          // 离开底部
          footerWrapRef.value.getStart()
        }
      }
    }

    // 回滚到顶部
    const scrollToTop = () => {
      if (homeRef.value) {
        homeRef.value.scrollTop = 0
      }
    }

    onMounted(() => {
      getInfo()
    })

    onMounted(() => {
      if (homeRef.value) {
        homeRef.value.addEventListener('scroll', handleScroll)
      }

      // 确保在挂载后获取正确的元素尺寸信息，因为可能在挂载时元素还未完全渲染好
      nextTick(() => {
        handleScroll()
      })

      getInfo()
    })

    onUnmounted(() => {
      if (homeRef.value) {
        window.removeEventListener('scroll', handleScroll)
      }
    })

    return {
      dataList,
      infoData,
      getInfo,
      pullClick,
      footerWrapRef,
      homeRef,
      consultShow,
      mobileDeRef,
      openMobileDe,
      baseInforDrawerRef,
      handleBaseInfo,
      scrollToTop,
      handleCase,
      caseDialogRef,
      caseImg
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>