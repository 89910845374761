// 整合营销页面配置
export const dataList = [
  {
    id: 'localLife',
    headerTile: '本地生活',
    title: '本地生活服务',
    bgUrl: new URL(`@/assets/image/mobileIntegratedSecond/localLifeBg.png`, import.meta.url).href,
    headerObj: {
      title: '本地生活服务',
      textList: [
        { center: '系统运营 + 流量运营 + 广告运营 + 私域运营一体化解决方案', class: 'one-line' },
        { center: '助力企业打造私域业态，提升企业GMV', class: 'two-line' },
      ],
      buttonText: '专属顾问'
    },
    problemObj: {
      title: '多样化服务提升您的GMV',
      type: 'card',
      list: [
        {
          cardUrl: new URL(`@/assets/image/mobileIntegratedSecond/xmpp.png`, import.meta.url).href,
        },
        {
          cardUrl: new URL(`@/assets/image/mobileIntegratedSecond/zbdb.png`, import.meta.url).href,
        },
        {
          cardUrl: new URL(`@/assets/image/mobileIntegratedSecond/jnpx.png`, import.meta.url).href,
        },
        {
          cardUrl: new URL(`@/assets/image/mobileIntegratedSecond/xxtd.png`, import.meta.url).href,
        }
      ],
    },
    exampleObj: {
      title: '品牌爆点打法',
      center: '赋能全行业商家聚势破圈',
      cardList: [
        {
          id: 'cbd',
          img: new URL(`@/assets/image/intelligentTool/bdsh/cbd.png`, import.meta.url).href,
          name: '茶白道',
          center: '茶百道 | 抖音本地生活首个直播GMV破亿品牌',
          tag: '直播投流 - 本地推',
          caseInfoImg: new URL(`@/assets/image/caseDialog/cbd.png`, import.meta.url).href,
        },
        {
          id: 'xc',
          img: new URL(`@/assets/image/intelligentTool/bdsh/xc.png`, import.meta.url).href,
          name: '喜茶',
          center: '喜茶 | 年度盛典抖音直播累计曝光量2.3亿',
          tag: '直播投流 - 本地推',
          caseInfoImg: new URL(`@/assets/image/caseDialog/xc.png`, import.meta.url).href,
        },
        {
          id: 'mxbc',
          img: new URL(`@/assets/image/intelligentTool/bdsh/mxbc.png`, import.meta.url).href,
          name: '蜜雪冰城',
          center: '蜜雪冰城 | 区域号日播创造单月佳绩770W+',
          tag: '年度运营 - 直播投流',
          caseInfoImg: new URL(`@/assets/image/caseDialog/mxbc.png`, import.meta.url).href,
        },
        {
          id: 'xlxs',
          img: new URL(`@/assets/image/intelligentTool/bdsh/xl.png`, import.meta.url).href,
          name: '西岭',
          center: '西岭 | 抖音团购带货周榜&月榜 TOP1',
          tag: '年度运营 - 直播投流',
          caseInfoImg: new URL(`@/assets/image/caseDialog/xlxs.png`, import.meta.url).href,
        },
      ]
    }
  },
  {
    id: 'eCommerceLive',
    headerTile: '电商直播服务',
    title: '电商直播服务',
    bgUrl: new URL(`@/assets/image/intelligentTool/dszbfw/bg.png`, import.meta.url).href,
    headerObj: {
      title: '电商直播服务',
      textList: [
        { center: '千场直播，200+客户信赖，GMV超20亿', class: 'one-line' },
        { center: '一站式AI+电销，全维度为企业赋能', class: 'two-line' },
      ],
      buttonText: '专属顾问'
    },
    problemObj: {
      title: '多样化服务提升您的GMV',
      type: 'card',
      list: [
        {
          cardUrl: new URL(`@/assets/image/intelligentTool/dszbfw/card-one.png`, import.meta.url).href,
        },
        {
          cardUrl: new URL(`@/assets/image/intelligentTool/dszbfw/card-two.png`, import.meta.url).href,
        },
        {
          cardUrl: new URL(`@/assets/image/intelligentTool/dszbfw/card-three.png`, import.meta.url).href,
        },
        {
          cardUrl: new URL(`@/assets/image/intelligentTool/dszbfw/card-four.png`, import.meta.url).href,
        },
        {
          cardUrl: new URL(`@/assets/image/intelligentTool/dszbfw/card-five.png`, import.meta.url).href,
        }
      ],
    },
    exampleObj: {
      title: '品牌爆点打法',
      center: '客户销量突破',
      cardList: [
        // {
        //   id: 'sls',
        //   img: new URL(`@/assets/image/intelligentTool/dszbfw/sls.png`, import.meta.url).href,
        //   name: '舒乐时',
        //   center: '舒乐时 | 从0到1打造抖音垂类爆款TOP级品牌',
        //   tag: '爆品打造 - 直播 - 短视频 - 店铺运营 - 明星达人 - 私域运营'
        // },
        {
          id: 'ssnz',
          img: new URL(`@/assets/image/intelligentTool/dszbfw/ssnz.png`, import.meta.url).href,
          name: '杉杉男装',
          center: '杉杉男装 | 冷启动打爆新品，店铺入选抖音好店合集',
          tag: '爆品打造 - 直播 - 短视频 - 投流 - 店铺运营'
        },
      ]
    }
  },
  {
    id: 'contentOp',
    headerTile: '内容运营服务',
    title: '内容运营服务',
    bgUrl: new URL(`@/assets/image/mobileIntegratedSecond/contentBg.png`, import.meta.url).href,
    headerObj: {
      title: '电商直播服务',
      textList: [
        { center: '全平台专业代运营，创作高质量内容，讲述品牌故事', class: 'one-line' },
      ],
      buttonText: '专属顾问'
    },
    problemObj: {
      title: '内容运营',
      type: 'card',
      list: [
        {
          cardUrl: new URL(`@/assets/image/intelligentTool/nryyfw/card-one.png`, import.meta.url).href,
        },
        {
          cardUrl: new URL(`@/assets/image/intelligentTool/nryyfw/card-two.png`, import.meta.url).href,
        },
        {
          cardUrl: new URL(`@/assets/image/intelligentTool/nryyfw/card-three.png`, import.meta.url).href,
        },
        {
          cardUrl: new URL(`@/assets/image/intelligentTool/nryyfw/card-four.png`, import.meta.url).href,
        },
        {
          cardUrl: new URL(`@/assets/image/intelligentTool/nryyfw/card-five.png`, import.meta.url).href,
        },
        {
          cardUrl: new URL(`@/assets/image/intelligentTool/nryyfw/card-six.png`, import.meta.url).href,
        }
      ],
    },
    exampleObj: {
      title: '策划专栏内容',
      center: '沉淀品牌内容资产',
      cardList: [
        {
          id: 'xhs',
          img: new URL(`@/assets/image/intelligentTool/nryyfw/xhs.png`, import.meta.url).href,
          name: '小红书',
          center: '小红书 | 内容运营账号单篇平均阅读量3W+',
          tag: '账号代运营 - IP策划 - 小红书',
          caseInfoImg: new URL(`@/assets/image/caseDialog/xhs.png`, import.meta.url).href,
        },
        {
          id: 'hlxgz',
          img: new URL(`@/assets/image/intelligentTool/nryyfw/hlxgz.png`, import.meta.url).href,
          name: '黄龙溪古镇',
          center: '黄龙溪古镇 | 策划专栏内容，打造古镇名片',
          tag: '自媒体代运营 - 微信公众号',
          caseInfoImg: new URL(`@/assets/image/caseDialog/hlxgz.png`, import.meta.url).href,
        },
        {
          id: 'tfld',
          img: new URL(`@/assets/image/intelligentTool/nryyfw/tfld.png`, import.meta.url).href,
          name: '天府绿道',
          center: '天府绿道 | 官方账号本地政务类排名TOP5',
          tag: '官抖代运营 - IP策划 - 短视频 - 矩阵号',
          caseInfoImg: new URL(`@/assets/image/caseDialog/tfld.png`, import.meta.url).href,
        },
        {
          id: 'bgy',
          img: new URL(`@/assets/image/intelligentTool/nryyfw/bgy.png`, import.meta.url).href,
          name: '百果园',
          center: '百果园 | 短视频引流直播间GMV突破500w+',
          tag: '账号代运营 - 短视频 - 直播',
          caseInfoImg: new URL(`@/assets/image/caseDialog/bgy.png`, import.meta.url).href,
        },
      ]
    }
  },
  {
    id: 'accompanyRunner',
    headerTile: '陪跑伴教服务',
    title: '陪跑伴教服务',
    bgUrl: new URL(`@/assets/image/mobileIntegratedSecond/accompanyBg.png`, import.meta.url).href,
    headerObj: {
      title: '陪跑伴教服务',
      textList: [
        { center: '全方位商家赋能，实战陪跑', class: 'one-line' },
        { center: '定制化教学，助力业绩提升', class: 'one-line' },
      ],
      buttonText: '专属顾问'
    },
    problemObj: {
      title: '一体化定制服务',
      type: 'card',
      list: [
        {
          cardUrl: new URL(`@/assets/image/intelligentTool/ppbjfw/card-one.png`, import.meta.url).href,
        },
        {
          cardUrl: new URL(`@/assets/image/intelligentTool/ppbjfw/card-two.png`, import.meta.url).href,
        },
        {
          cardUrl: new URL(`@/assets/image/intelligentTool/ppbjfw/card-three.png`, import.meta.url).href,
        },
      ],
    },
    exampleObj: {
      title: '深入解析市场趋势',
      center: '定制化实战陪跑，帮助业绩增长',
      cardList: [
        {
          id: 'lx',
          img: new URL(`@/assets/image/intelligentTool/ppbjfw/lx.png`, import.meta.url).href,
          name: '理想汽车',
          center: '理想汽车 | 单店单月线索量同比增长1000%+',
          tag: '内容策划 - 线索收集 - 获客',
          caseInfoImg: new URL(`@/assets/image/caseDialog/lx.png`, import.meta.url).href,
        },
        {
          id: 'lzyd',
          img: new URL(`@/assets/image/intelligentTool/ppbjfw/lzyd.png`, import.meta.url).href,
          name: '泸州移动',
          center: '泸州移动 | 区域品牌曝光量提高800%',
          tag: '培训赋能 - 实战陪跑',
          caseInfoImg: new URL(`@/assets/image/caseDialog/lzyd.png`, import.meta.url).href,
        },
      ]
    }
  },
  {
    id: 'brandComm',
    headerTile: '品牌传播服务',
    title: '品牌传播服务',
    bgUrl: new URL(`@/assets/image/intelligentTool/ppcbfw/bg.png`, import.meta.url).href,
    headerObj: {
      title: '品牌传播服务',
      textList: [
        { center: '整合营销专家，助力品牌高效传播，实现品效合一', class: 'one-line' },
      ],
      buttonText: '专属顾问'
    },
    problemObj: {
      title: '整合专家一站式服务',
      type: 'card',
      list: [
        {
          cardUrl: new URL(`@/assets/image/intelligentTool/ppcbfw/card-one.png`, import.meta.url).href,
        },
        {
          cardUrl: new URL(`@/assets/image/intelligentTool/ppcbfw/card-two.png`, import.meta.url).href,
        },
        {
          cardUrl: new URL(`@/assets/image/intelligentTool/ppcbfw/card-three.png`, import.meta.url).href,
        },
        {
          cardUrl: new URL(`@/assets/image/intelligentTool/ppcbfw/card-four.png`, import.meta.url).href,
        },
        {
          cardUrl: new URL(`@/assets/image/intelligentTool/ppcbfw/card-five.png`, import.meta.url).href,
        },
      ],
    },
    exampleObj: {
      title: '品牌爆点打法',
      center: '赋能全行业商家聚势破圈',
      cardList: [
        {
          id: 'cdhlg',
          img: new URL(`@/assets/image/intelligentTool/ppcbfw/cdhlg.png`, import.meta.url).href,
          name: '成都欢乐谷',
          center: '成都欢乐谷 | 万圣奇幻夜唐僧NPC火爆全网',
          tag: '年度运营 - 热点策划 - NPC打造 - 短视频 - 挑战赛 - 品牌广告 - 信息流投放',
          caseInfoImg: new URL(`@/assets/image/caseDialog/cdhlg.png`, import.meta.url).href,
        },
        {
          id: 'hls',
          img: new URL(`@/assets/image/intelligentTool/ppcbfw/hls.png`, import.meta.url).href,
          name: '华莱士',
          center: '华莱士 | 新品整合营销话题曝光量达7亿+',
          tag: '整合营销 - 话题 - 全民任务 - 达人 - 直播投流',
          caseInfoImg: new URL(`@/assets/image/caseDialog/hls.png`, import.meta.url).href,
        },
        {
          id: 'cdjdhy',
          img: new URL(`@/assets/image/intelligentTool/ppcbfw/cdjdhy.png`, import.meta.url).href,
          name: '成都极地海洋公园',
          center: '成都极地海洋公园 | 话题热度累计高达1300W+',
          tag: '年度运营 - 热点策划 - NPC打造 - 短视频 - 品牌广告',
          caseInfoImg: new URL(`@/assets/image/caseDialog/cdjdhy.png`, import.meta.url).href,
        },
        {
          id: 'hsay',
          img: new URL(`@/assets/image/intelligentTool/ppcbfw/hsay.png`, import.meta.url).href,
          name: '沪上阿姨',
          center: '沪上阿姨 | 10周年品牌换新，达成1.3亿次话题曝光',
          tag: '整合营销 - 话题 - 挑战赛 - 硬广',
          caseInfoImg: new URL(`@/assets/image/caseDialog/hsay.png`, import.meta.url).href,
        },
      ]
    }
  },
  {
    id: 'performanceAd',
    headerTile: '效果广告',
    title: '效果广告服务',
    bgUrl: new URL(`@/assets/image/mobileIntegratedSecond/performanceBg.png`, import.meta.url).href,
    headerObj: {
      title: '效果广告服务',
      textList: [
        { center: '巨量引擎认证服务商，专业团队定制营销', class: 'one-line' },
        { center: '助力品牌全链路运营，实现增长突破！', class: 'two-line' },
      ],
      buttonText: '专属顾问'
    },
    problemObj: {
      title: '全方位解决方案助力品牌增长',
      type: 'list',
      list: [
        { title: '巨量千川代运营' },
        { title: '巨量信息流获客' },
        { title: '巨量本地推代运营' },
        { title: '广告素材制作' },
        { title: '线上精准获客/涨粉' },
        { title: '云图人群包梳理' },
        { title: '投放规划及复盘' },
        { title: '人货场优化' }
      ],
      btnText: '获取解决方案',
    },
    exampleObj: {
      title: '品牌爆点打法',
      center: '赋能全行业商家聚势破圈',
      cardList: [
        {
          id: 'tescy',
          img: new URL(`@/assets/image/intelligentTool/xgggfw/tescy.png`, import.meta.url).href,
          name: '太二酸菜鱼',
          center: '太二酸菜鱼 | 全国场首播十二小时GMV突破1亿',
          tag: '直播投流 - 巨量本地推',
          caseInfoImg: new URL(`@/assets/image/caseDialog/tescy.png`, import.meta.url).href,
        },
        {
          id: 'hqls',
          img: new URL(`@/assets/image/intelligentTool/xgggfw/hqls.png`, import.meta.url).href,
          name: '红旗连锁',
          center: '红旗连锁 | 单日GMV突破1亿+，打爆成都本地商超标杆',
          tag: '整合营销 - 巨量本地推 - IP联动 - 品牌广告 - 品专 - 投流',
          caseInfoImg: new URL(`@/assets/image/caseDialog/hqls.png`, import.meta.url).href,
        },
        {
          id: 'ydddc',
          img: new URL(`@/assets/image/intelligentTool/xgggfw/ydddc.png`, import.meta.url).href,
          name: '雅迪',
          center: '雅迪 | 登顶区域团购带货榜TOP1',
          tag: '直播投流 - 巨量本地推 - 短视频',
          caseInfoImg: new URL(`@/assets/image/caseDialog/ydddc.png`, import.meta.url).href,
        },
        {
          id: 'chff',
          img: new URL(`@/assets/image/intelligentTool/xgggfw/chff.png`, import.meta.url).href,
          name: '彩虹夫妇',
          center: '彩虹夫妇 | 单日GMV2.3亿+，登顶带货榜NO.1',
          tag: '达人 - 直播投流 - 千川代运营',
          caseInfoImg: new URL(`@/assets/image/caseDialog/chff.png`, import.meta.url).href,
        },
      ]
    }
  }
]
