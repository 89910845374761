<template>
  <div class="mobileHome-cantainer" ref="homeRef">
    <!-- 抬头 -->
    <MobileHeaderWrap />

    <div class="intro-wrap">
      <div class="intro-title">
        <div>品质服务</div>
        <div>用心为您</div>
      </div>
      <div class="intro-button" @click="handleBaseInfo">业务咨询</div>
    </div>

    <div class="text-dec-wrap">
      <div class="title">关于我们</div>
      <img
        class="img"
        src="../../assets/image/mobileAbout/textDec.png"
        alt=""
      />
    </div>

    <div class="honor-wrap">
      <img
        class="img"
        src="../../assets/image/mobileAbout/honorList.png"
        alt=""
      />
    </div>

    <div class="performance-wrap">
      <div class="title">业绩展示</div>
      <img
        class="img"
        src="../../assets/image/mobileAbout/performanceData.png"
        alt=""
      />
    </div>

    <!-- 底部 -->
    <MobileFooter ref="footerWrapRef" />

    <!-- 业务咨询footer -->
    <BusinessConsult :classNameShow="consultShow" />

    <BaseInforDrawer ref="baseInforDrawerRef" />
  </div>
</template>

<script>
import { onMounted, ref, onUnmounted, nextTick } from 'vue'
import MobileFooter from '/@/components/mobileFooter/index.vue'
import MobileHeaderWrap from '/@/components/mobileHeaderWrap/index.vue'
import BusinessConsult from '/@/components/businessConsult/index.vue'
import BaseInforDrawer from '/@/components/baseInforDrawer/index.vue'

export default {
  name: 'MobileAbout',
  components: {
    MobileFooter,
    MobileHeaderWrap,
    BusinessConsult,
    BaseInforDrawer
  },
  setup() {
    const footerWrapRef = ref(null)
    const homeRef = ref(null)
    const consultShow = ref(true)
    const baseInforDrawerRef = ref(null)

    let consultTimer = null

    const handleBaseInfo = () => {
      baseInforDrawerRef.value.open()
    }

    const handleScroll = () => {
      clearTimeout(consultTimer)
      consultShow.value = false
      consultTimer = setTimeout(() => {
        consultShow.value = true
      }, 500) // 这里设置1000毫秒（1秒），表示停止滚动1秒后恢复显示，可根据实际需求调整时间

      // 底部滚动到底部触发
      if (footerWrapRef.value) {
        const footerRect = footerWrapRef.value.$el
          .querySelector('.footer-wrap')
          .getBoundingClientRect()
        const windowHeight = window.innerHeight
        if (Math.floor(footerRect.bottom) <= windowHeight) {
          // 到达底部
          footerWrapRef.value.startAnimation()
        } else {
          // 离开底部
          footerWrapRef.value.getStart()
        }
      }
    }

    onMounted(() => {
      if (homeRef.value) {
        homeRef.value.addEventListener('scroll', handleScroll)
      }

      // 确保在挂载后获取正确的元素尺寸信息，因为可能在挂载时元素还未完全渲染好
      nextTick(() => {
        handleScroll()
      })
    })

    onUnmounted(() => {
      if (homeRef.value) {
        window.removeEventListener('scroll', handleScroll)
      }
    })

    return {
      homeRef,
      footerWrapRef,
      consultShow,
      baseInforDrawerRef,
      handleBaseInfo
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>