import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js';
import dragscroll from '@/utils/directives.js'
import 'element-plus/dist/index.css';
import ElementPlus from 'element-plus';

// 获取HTML根元素
const html = document.documentElement;
// 设置根元素字体大小为50px（换算成rem就是1rem）
html.style.fontSize = '50px';

const app = createApp(App);
app.use(router);
app.use(ElementPlus);
app.mount('#app');
app.directive('dragscroll',dragscroll)
document.body.style.margin = '0';
document.title = '融智互动-官方网站';
