<template>
  <div class="mobileHome-cantainer" ref="homeRef">
    <!-- 抬头 -->
    <MobileHeaderWrap />

    <div class="intro-wrap">
      <div class="intro-title">整合营销专家</div>
      <div class="intro-text">精准策略，全面整合，让品牌影响力无处不在</div>
      <div class="intro-text">选择我们，开启您的营销新篇章！</div>
      <div class="intro-button" @click="handleBaseInfo">业务咨询</div>
    </div>

    <!-- 智能营销工具 -->
    <div class="smart-wrap">
      <div class="title-wrap">
        <div class="left">
          <div class="text en">SMART MARKETING TOOLS</div>
          <div class="text ch">智能营销工具</div>
        </div>

        <div class="market-dotte"></div>
      </div>

      <div ref="chatRef">
        <div class="smart-chat-box">
          <div class="chart-bg">
            <div
              class="smart-chart-right-img right"
              :class="
                showIndex.includes(0) ? 'smart-chart-img-right-block' : ''
              "
            >
              <img
                class="left-img"
                src="../../assets/image/home/customer1.png"
                alt=""
              />
            </div>
            <div
              class="smart-chart-img left"
              :class="showIndex.includes(1) ? 'smart-chart-img-left-block' : ''"
            >
              <img
                class="right-img"
                src="../../assets/image/home/client1.png"
                alt=""
              />
            </div>
            <div
              class="smart-chart-right-img right"
              :class="
                showIndex.includes(2) ? 'smart-chart-img-right-block' : ''
              "
            >
              <img
                class="left-img"
                src="../../assets/image/home/customer2.png"
                alt=""
              />
            </div>
            <div
              class="smart-chart-img left"
              :class="showIndex.includes(3) ? 'smart-chart-img-left-block' : ''"
            >
              <img
                class="right-last-img"
                src="../../assets/image/home/client2.png"
                alt=""
              />
            </div>
          </div>

          <div class="chart-content">
            <div class="chart-content-item">
              <div class="title">大模型</div>
              <div class="dec">沟通更准确、更友好、更全面</div>
            </div>

            <div class="chart-content-item">
              <div class="title">快速集成</div>
              <div class="dec">秒级理解原始文档，智能客服，一键上岗</div>
            </div>

            <div class="chart-content-item">
              <div class="title">高效运营</div>
              <div class="dec">大模型协助机器人运营优化，省心省力</div>
            </div>
          </div>

          <div class="chart-button" @click="handleBaseInfo">申请试用</div>
        </div>
      </div>
    </div>

    <!-- 服务内容 -->
    <div class="service-wrap">
      <div class="title-wrap">
        <div class="left">
          <div class="text en">SERVICE CONTENT</div>
          <div class="text ch">服务内容</div>
        </div>

        <div class="market-dotte"></div>
      </div>
    </div>

    <div class="img-list-wrap">
      <img
        class="img"
        src="../../assets/image/mobileHome/service-one.png"
        alt=""
        @click="handleDetail('localLife')"
      />
      <img
        class="img"
        src="../../assets/image/mobileHome/service-two.png"
        alt=""
        @click="handleDetail('performanceAd')"
      />
      <img
        class="img"
        src="../../assets/image/mobileHome/service-three.png"
        alt=""
        @click="handleDetail('brandComm')"
      />
      <img
        class="img"
        src="../../assets/image/mobileHome/service-four.png"
        alt=""
        @click="handleDetail('contentOp')"
      />
    </div>

    <!-- 底部 -->
    <MobileFooter ref="footerWrapRef" />

    <!-- 业务咨询footer -->
    <BusinessConsult :classNameShow="consultShow" />

    <BaseInforDrawer ref="baseInforDrawerRef" />
  </div>
</template>

<script>
import { onMounted, ref, onUnmounted, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import MobileFooter from '/@/components/mobileFooter/index.vue'
import MobileHeaderWrap from '/@/components/mobileHeaderWrap/index.vue'
import BusinessConsult from '/@/components/businessConsult/index.vue'
import BaseInforDrawer from '/@/components/baseInforDrawer/index.vue'

export default {
  name: 'mobileHomePage',
  components: {
    MobileFooter,
    MobileHeaderWrap,
    BusinessConsult,
    BaseInforDrawer
  },
  setup() {
    const router = useRouter()

    const footerWrapRef = ref(null)
    const homeRef = ref(null)
    const showIndex = ref([])
    const chatRef = ref(null)
    const consultShow = ref(true)
    const baseInforDrawerRef = ref(null)

    let consultTimer = null
    let timer = null
    let isAnimating = false
    // 满足条件只触发一次开关
    let hasTriggeredOnce = false

    const handleBaseInfo = () => {
      baseInforDrawerRef.value.open()
    }

    // 进入二级菜单
    const handleDetail = (id) => {
      router.push({ name: 'MobileIntegratedSecond', params: { id: id } })
    }

    const handleScroll = () => {
      clearTimeout(consultTimer)
      consultShow.value = false
      consultTimer = setTimeout(() => {
        consultShow.value = true
      }, 500) // 这里设置1000毫秒（1秒），表示停止滚动1秒后恢复显示，可根据实际需求调整时间

      // 聊天动效逻辑
      const top = chatRef.value.getBoundingClientRect().top
      if (top <= 400 && top >= -760 && !hasTriggeredOnce) {
        hasTriggeredOnce = true
        if (!isAnimating) {
          isAnimating = true
          timer = setInterval(() => {
            if (showIndex.value.length < 4) {
              showIndex.value.push(showIndex.value.length)
            } else {
              clearInterval(timer)
              timer = null
              isAnimating = false
            }
          }, 900)
        }
      } else if (hasTriggeredOnce && (top > 400 || top < -760)) {
        if (timer) {
          clearInterval(timer)
          timer = null
          isAnimating = false
        }
        showIndex.value = []
        hasTriggeredOnce = false
      }

      // 底部滚动到底部触发
      if (footerWrapRef.value) {
        const footerRect = footerWrapRef.value.$el
          .querySelector('.footer-wrap')
          .getBoundingClientRect()
        const windowHeight = window.innerHeight
        if (Math.floor(footerRect.bottom) <= windowHeight) {
          // 到达底部
          footerWrapRef.value.startAnimation()
        } else {
          // 离开底部
          footerWrapRef.value.getStart()
        }
      }
    }

    onMounted(() => {
      if (homeRef.value) {
        homeRef.value.addEventListener('scroll', handleScroll)
      }

      // 确保在挂载后获取正确的元素尺寸信息，因为可能在挂载时元素还未完全渲染好
      nextTick(() => {
        handleScroll()
      })
    })

    onUnmounted(() => {
      if (homeRef.value) {
        window.removeEventListener('scroll', handleScroll)
      }
    })

    return {
      homeRef,
      footerWrapRef,
      chatRef,
      showIndex,
      consultShow,
      baseInforDrawerRef,
      handleBaseInfo,
      handleDetail
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>