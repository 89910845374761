<template>
  <div class="mobile-header-wrap">
    <img
      class="header-logo"
      src="../../assets/image/mobileHeader/headerLogo.png"
      alt=""
    />
    <img
      class="menu-logo"
      src="../../assets/image/mobileHeader/menuLogo.svg"
      alt=""
      @click="open"
    />

    <el-drawer v-model="drawer" direction="ttb" size="100%" destroy-on-close>
      <el-menu>
        <template v-for="(item, index) in headerWarp" :key="index">
          <el-sub-menu v-if="item.pullList.length > 0" :index="item.index">
            <template #title>{{ item.title }}</template>
            <el-menu-item-group>
              <el-menu-item
                class="second-menu"
                v-for="(pullItem, pullIndex) in item.pullList"
                :key="pullIndex"
                :index="`${item.index}-${pullIndex}`"
                @click="pullClick(pullItem)"
              >
                <router-link :to="pullItem.url" class="router-link-box">
                  {{ pullItem.title }}
                </router-link>
              </el-menu-item>
            </el-menu-item-group>
          </el-sub-menu>
          <el-menu-item
            v-else
            class="first-menu"
            :index="item.index"
            @click="pullClick(item)"
          >
            <template #title>
              <router-link :to="item.url" class="router-link-box">
                {{ item.title }}
              </router-link>
            </template>
          </el-menu-item>
        </template>
      </el-menu>
    </el-drawer>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'mobileHeaderWrap',
  components: {},
  setup(_props, { emit }) {
    const drawer = ref(false)
    const headerWarp = ref([
      {
        title: '首页',
        url: '/',
        pullList: [],
        index: '1'
      },
      {
        title: '整合营销',
        url: '/integrated',
        index: '2',
        pullList: [
          {
            id: 'localLife',
            title: '本地生活',
            url: '/integrated/secondLevel/localLife',
            index: '2-1'
          },
          // {
          //   id: 'eCommerceLive',
          //   title: '电商直播服务',
          //   url: '/integrated/secondLevel/eCommerceLive',
          //   index: '2-2'
          // },
          {
            id: 'performanceAd',
            title: '效果广告',
            url: '/integrated/secondLevel/performanceAd',
            index: '2-3'
          },
          {
            id: 'brandComm',
            title: '品牌传播服务',
            url: '/integrated/secondLevel/brandComm',
            index: '2-4'
          },
          {
            id: 'contentOp',
            title: '内容运营服务',
            url: '/integrated/secondLevel/contentOp',
            index: '2-5'
          },
          {
            id: 'accompanyRunner',
            title: '陪跑伴教服务',
            url: '/integrated/secondLevel/accompanyRunner',
            index: '2-6'
          }
        ]
      },
      {
        title: '智能工具',
        url: '/intelligentTool',
        index: '3',
        pullList: [
          {
            id: 'customerService',
            title: '融智AI客服工具',
            url: '/intelligentTool',
            index: '3-1'
          }
        ]
      },
      {
        title: '品牌孵化',
        url: '/brandIncubation',
        pullList: [],
        index: '4'
      },
      {
        title: '关于我们',
        url: '/about',
        pullList: [],
        index: '5'
      }
    ])

    const pullClick = (pull) => {
      // 传参给父组件
      emit('pullClick', pull)
      drawer.value = false
    }

    const open = () => {
      drawer.value = true
    }
    return {
      drawer,
      headerWarp,
      open,
      pullClick
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>