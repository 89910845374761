<template>
  <div>
    <div
      class="business-consult-wrap"
      :class="$props.classNameShow ? 'slide-up' : 'slide-down'"
    >
      <div class="img-list">
        <img
          class="img"
          src="../../assets/image/businessConsult/phone-icon.png"
          alt=""
          @click="callNumber"
        />
        <img
          class="img"
          src="../../assets/image/businessConsult/counselor-icon.png"
          alt=""
          @click="openMobileDe"
        />
      </div>
      <div class="business-btn" @click="openBusConsult">业务咨询</div>
    </div>

    <InformationDrawer ref="inforDrawerRef" />

    <MobileDeConsultant ref="mobileDeRef" />
  </div>
</template>

<script>
import { ref } from 'vue'
import InformationDrawer from '/@/components/informationDrawer/index.vue'
import MobileDeConsultant from '/@/components/mobileDeConsultant/index.vue'
export default {
  name: 'businessConsult',
  components: {
    InformationDrawer,
    MobileDeConsultant
  },
  props: {
    classNameShow: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const inforDrawerRef = ref(null)
    const mobileDeRef = ref(null)

    const openMobileDe = () => {
      mobileDeRef.value.open()
    }

    const callNumber = () => {
      window.location.href = `tel:18583992018`
    }

    const openBusConsult = () => {
      inforDrawerRef.value.open()
    }

    return {
      callNumber,
      inforDrawerRef,
      openBusConsult,
      mobileDeRef,
      openMobileDe
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>