<template>
  <div>
    <!-- 二级菜单页面 -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'MobileIntegrated',
  components: {},
  setup() {
    return {}
  }
}
</script>