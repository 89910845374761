<template>
  <div class="case-detail-cantainer" ref="caseDetailRef">
    <!-- 案例详情页 -->
    <div class="header-box">
      <HeaderWrap />
    </div>

    <div class="case-detail-wrap" v-if="info && info.id != 'fss'">
      <div class="header-obj-wrap">
        <img class="head-img" :src="info.headerObj.headerImg" alt="" />
        <div class="title">{{ info.headerObj.title }}</div>
      </div>

      <!-- 舒乐时 -->
      <div
        v-if="info.recordObj && info.recordObj.type == 'notRecordList'"
        class="detail-obj-wrap"
        :class="info?.backgroundClass"
      >
        <div>
          <div v-html="info.headerObj.dec"></div>
          <div class="record-obj-wrap">
            <div class="left">
              <img :src="info.recordObj.recordImg" />
            </div>

            <div class="hot-right">
              <div class="hot-title">
                {{ info.recordObj.title }}
              </div>
              <div class="top">{{ info.recordObj.top }}</div>
              <img class="img" :src="info.recordObj.rightUrl" alt="" />
            </div>
          </div>
        </div>
      </div>

      <!-- 衫衫男装 -->
      <div
        v-else-if="
          info.recordObj && info.recordObj.type == 'notRecordList-ssnz'
        "
        class="detail-obj-wrap"
        :style="info?.recordObj?.recordStyle"
      >
        <div>
          <div v-html="info.headerObj.dec"></div>
          <div class="ssnz-wrap">
            <div
              v-for="(ssnzItem, ssnzIndex) in info.recordObj.list"
              :key="ssnzIndex"
              class="ssnz-item"
            >
              <span class="title">{{ ssnzItem.title }}</span>
              <div
                v-for="(listItem, listIndex) in ssnzItem.dec"
                :key="listIndex"
                class="list-item"
              >
                {{ listItem }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-else-if="info.recordObj"
        class="detail-obj-wrap"
        :style="info?.recordObj?.recordStyle"
        :class="info?.backgroundClass"
      >
        <div>
          <div v-html="info.headerObj.dec"></div>
          <div class="record-obj-wrap">
            <div v-if="info.recordObj.recordImg" class="left">
              <img :src="info.recordObj.recordImg" />
            </div>
            <div class="right" :class="info?.recordObj?.recordRClass">
              <div v-if="info.recordObj.title" class="title">
                {{ info.recordObj.title }}
              </div>
              <div v-if="info.recordObj?.recordTitle" class="record-title">
                {{ info.recordObj.recordTitle }}
              </div>
              <div
                v-if="info.recordObj?.recordDecStr"
                v-html="info.recordObj?.recordDecStr"
              ></div>
              <div
                v-for="(recordItem, recordindex) in info.recordObj.recordList"
                :key="recordindex"
                class="record-list-wrap"
              >
                <div class="name">{{ recordItem.name }}</div>
                <div class="record">
                  {{ recordItem.record
                  }}<span style="font-family: none; font-size: 24px">{{
                    recordItem.unit
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="detail-obj-wrap" :class="info?.backgroundClass">
        <div>
          <div v-html="info.headerObj.dec"></div>
        </div>
      </div>

      <!-- 自定义区域第一部分 -->
      <!-- 1.图片列表 -->
      <div
        v-if="info?.particularObj?.type == 'imgList'"
        class="particular-imgs-wrap"
      >
        <div class="particular-imgs-box">
          <div
            class="img-item"
            v-for="(imgItem, imgIndex) in info.particularObj.list"
            :key="imgIndex"
          >
            <div
              v-if="info.particularObj?.decStr"
              v-html="info.particularObj.decStr"
            ></div>
            <img :src="imgItem.url" :style="`width: ${imgItem.width}`" alt="" />
            <div v-if="imgItem.title" class="title">{{ imgItem.title }}</div>
          </div>
        </div>
      </div>

      <!-- 2.单图位移 -->
      <div
        v-if="info?.particularObj?.type == 'imgTrans'"
        class="particular-imgs-trans-wrap"
      >
        <div
          class="img-item"
          v-for="(imgItem, imgIndex) in info.particularObj.list"
          :key="imgIndex"
        >
          <img :src="imgItem.url" :style="`width: ${imgItem.width}`" alt="" />
          <div v-if="imgItem.title" class="title">{{ imgItem.title }}</div>
        </div>
      </div>

      <!-- 3.舒乐时 -->
      <div
        v-if="info?.particularObj?.type == 'imgListTop'"
        class="particular-imgs-top-wrap"
        :style="`margin-left: ${info.particularObj.left}`"
      >
        <div
          class="img-item"
          v-for="(imgItem, imgIndex) in info.particularObj.list"
          :key="imgIndex"
        >
          <div v-if="imgItem.title" class="title">
            <div
              v-for="(titleItem, titleIndex) in imgItem.title"
              :key="titleIndex"
              class="title-item"
            >
              {{ titleItem }}
            </div>
          </div>
          <div v-if="imgItem.num" class="num">
            {{ imgItem.num
            }}<span style="font-size: 34px">{{ imgItem.unit }}</span>
          </div>
          <img :src="imgItem.url" :style="`width: ${imgItem.width}`" alt="" />
        </div>
      </div>

      <!-- 4.喜茶 -->
      <div
        v-if="info?.particularObj?.type == 'decList'"
        class="particular-xc-wrap"
      >
        <div>
          <span class="tag-title">{{ info.particularObj.title }}</span>
          <div
            class="dec-item"
            v-for="(imgItem, imgIndex) in info.particularObj.list"
            :key="imgIndex"
          >
            <div v-if="imgItem.title" class="title">{{ imgItem.title }}</div>
            <div v-html="imgItem.decStr"></div>
            <template v-if="imgItem?.imgs">
              <img
                v-for="(imgItem, imgIndex) in imgItem?.imgs"
                :key="imgIndex"
                :src="imgItem.url"
                :style="`width: ${imgItem.width}; margin: 38px 0 80px`"
                alt=""
              />
            </template>
          </div>
        </div>
      </div>

      <!-- 自定义区域第二部分 -->
      <!-- 成都欢乐谷 -->
      <div
        v-if="
          info.particularObj?.particularDecObj &&
          info.particularObj?.particularDecObj?.type == 'mulImg'
        "
        class="particular-dec-mulImg-wrap"
      >
        <div class="mulImg-title">
          {{ info.particularObj.particularDecObj.title }}
        </div>
        <div>
          <div
            v-for="(particularDecItem, particularDecIndex) in info.particularObj
              .particularDecObj.list"
            :key="particularDecIndex"
            class="mulImg-item"
          >
            <div
              class="img-item"
              v-for="(imgItem, imgIndex) in particularDecItem"
              :key="imgIndex"
            >
              <img
                :src="imgItem.url"
                :style="`width: ${imgItem.width}`"
                alt=""
              />
              <div v-if="imgItem.title" class="title">{{ imgItem.title }}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 天府绿道 -->
      <div
        v-if="info.particularObj?.recordObj"
        class="particular-detail-obj-wrap"
        style="background-color: #fff5f5"
        :style="info?.recordObj?.recordStyle"
      >
        <div>
          <div class="record-obj-wrap">
            <div v-if="info.particularObj.recordObj.recordImg" class="left">
              <img :src="info.particularObj.recordObj.recordImg" />
            </div>
            <div class="right">
              <div
                v-if="info.particularObj.recordObj?.recordTitle"
                class="record-title"
              >
                {{ info.particularObj.recordObj?.recordTitle }}
              </div>
              <div
                v-if="info.particularObj.recordObj?.recordDecStr"
                v-html="info.particularObj.recordObj?.recordDecStr"
              ></div>
              <div
                v-for="(recordItem, recordindex) in info.particularObj.recordObj
                  .recordList"
                :key="recordindex"
                class="record-list-wrap"
              >
                <div class="name">{{ recordItem.name }}</div>
                <div class="record">
                  {{ recordItem.record
                  }}<span style="font-family: none; font-size: 24px">{{
                    recordItem.unit
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 天府绿道 -->
      <div
        v-if="info?.particularObj?.recordObj?.mulImg"
        class="particularObj-recordObj-mulImg-wrap"
      >
        <div
          class="img-item"
          v-for="(imgItem, imgIndex) in info.particularObj.recordObj.mulImg"
          :key="imgIndex"
        >
          <img :src="imgItem.url" :style="`width: ${imgItem.width}`" alt="" />
          <div v-if="imgItem.title" class="title">{{ imgItem.title }}</div>
        </div>
      </div>
    </div>
    <div v-else-if="info && info.id == 'fss'" class="case-detail-fss">
      <img
        class="header-img"
        src="../../assets/image/detail/fss-header.png"
        alt=""
      />

      <img
        class="header-text-img"
        src="../../assets/image/detail/fss-header-text.png"
        alt=""
      />

      <img
        class="header-time-img"
        src="../../assets/image/detail/fss-time-line.png"
        alt=""
      />
    </div>

    <!-- 底部 -->
    <Footer ref="footerWrapRef" />
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import HeaderWrap from '/@/components/headerWrap/index.vue'
import { dataList } from '../../utils/caseInfoIndex'
import { onMounted, ref, onUnmounted } from 'vue'
import Footer from '/@/components/footer/index.vue'

export default {
  name: 'CaseDetail',
  components: {
    HeaderWrap,
    Footer
  },
  setup() {
    const route = useRoute()

    const footerWrapRef = ref(null)
    const caseDetailRef = ref(null)
    const caseId = route.params.id
    const dataInfo = dataList
    const info = ref(null)

    const infoFetch = (id) => {
      const data = dataInfo.find((item, index) => {
        if (item.id == id) {
          return dataInfo[index]
        }
      })

      return data ? data : {}
    }

    // 查询详情
    const getInfo = () => {
      info.value = infoFetch(caseId)
    }

    const handleScroll = () => {
      // 底部滚动到底部触发
      if (footerWrapRef.value) {
        const footerRect = footerWrapRef.value.$el
          .querySelector('.footer-wrap')
          .getBoundingClientRect()
        const windowHeight = window.innerHeight
        if (Math.floor(footerRect.bottom) <= windowHeight) {
          // 到达底部
          footerWrapRef.value.startAnimation()
        } else {
          // 离开底部
          footerWrapRef.value.getStart()
        }
      }
    }

    onMounted(() => {
      getInfo()
      caseDetailRef.value.addEventListener('scroll', handleScroll)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll)
    })

    return {
      caseId,
      dataInfo,
      info,
      caseDetailRef,
      footerWrapRef
    }
  }
}
</script>
<style lang="scss">
@import './index.scss';
</style>
